<template>
  <div>
    <v-btn
      v-if="itemId"
      icon
      large
      class="badge-btn"
      @click.stop="showDialog()"
    >
      <v-img contain width="40px" :src="badgeImageURL"></v-img>
    </v-btn>
    <v-btn
      v-else
      class="bg-secondary text-color-white text-capitalize"
      @click.stop="showDialog()"
    >
      Add New
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="400" scrollable>
      <v-card>
        <v-card-title class="title">{{ title }}</v-card-title>
        <v-card-text>
          <badge-form
            ref="badgeForm"
            :key="componentKey"
            :form="form.badge"
            :itemId="itemId"
          >
          </badge-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" color="error">
            Cancel
          </v-btn>
          <v-btn
            class="bg-secondary text-color-white"
            @click="submit()"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const badgesRepository = RepositoryFactory.get("badge");

const BadgeForm = () => import("../forms/BadgeForm.vue");

export default {
  components: {
    BadgeForm
  },
  data: () => ({
    title: "Create Badge",
    dialog: false,
    componentKey: 0,
    form: {},
    // itemId: null,
    loading: false,
    eventName: "reloadItems_badge"
  }),
  props: {
    itemId: {
      type: Number,
      default: null
    },
    badgeImageURL: {
      type: String,
      default: null
    }
  },
  methods: {
    async getItem() {
      await badgesRepository
        .show(this.itemId)
        .then(response => {
          let {
            data: { responseData: item }
          } = response;

          this.form = {
            badge: {
              name: item.name,
              description: item.description,
              image: this.badgeImageURL,
              category: item.category
            }
          };
        })
        .catch(error => {
          console.log({ error });
        });
    },
    async showDialog() {
      if (this.itemId) {
        await this.getItem();
        this.title = "Edit Badge";
      }
      this.dialog = await true;
    },
    closeDialog() {
      this.componentKey++;
      this.dialog = false;
    },
    create(form) {
      badgesRepository
        .create(form)
        .then(response => {
          console.log({ response }, "create");

          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Created.");

          GLOBAL_EVENT_BUS.$emit(this.eventName);
        })
        .catch(error => {
          console.log({ error });

          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];

            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    },
    update(form) {
      badgesRepository
        .update(this.itemId, form)
        .then(response => {
          console.log({ response }, "update");

          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Created.");

          GLOBAL_EVENT_BUS.$emit(this.eventName);
        })
        .catch(error => {
          console.log({ error });

          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];

            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    },
    submit() {
      let badgeForm = this.$refs.badgeForm;

      let valid = badgeForm.validate();

      if (valid) {
        this.loading = true;

        let form = badgeForm.form;
        form.image = badgeForm.file;

        form = this.setFormData(form);

        if (this.itemId) {
          this.update(form);
        } else {
          this.create(form);
        }
      }
    },
    setFormData(form) {
      if (form.image) {
        let formData = new FormData();

        formData.append("name", form.name);
        formData.append("description", form.description);
        formData.append("image", form.image);
        formData.append("category", form.category);

        if (this.itemId) {
          formData.append("_method", "PUT");
        }

        return formData;
      }

      if (this.itemId) {
        form._method = "PUT";
      }

      return form;
    }
  }
};
</script>
